#dashboard {
	margin: auto;
	& {
		padding-top: 40px;
	}

	#top-content-container {
		margin: 48px 0;
		#notifications-container {
			padding: 0.8rem 40px;
			border-radius: 5rem;
			margin-bottom: 0.25rem;
			border: 1px solid #d0d8bb;
			gap: 14px;
		}
		& > div {
			background: var(--neon-light);
			color: var(--black);
		}
	}

	#add-category {
		border-radius: 1.5rem;
		padding: 36px 48px 38px 36px;
		/* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
		box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	}

	#add-category button {
		border-radius: 50%;
		overflow: hidden;
		animation-name: floating;
		animation-duration: 2s;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in-out;
	}

	#add-category svg#go {
		background: var(--black);
		padding: 14px;
		fill: var(--neon-light);
		font-size: 20px;
	}

	#add-category svg#add-label {
		font-size: 30px;
	}

	#add-category > span {
		display: flex;
		align-items: center;
		gap: 10px;
		opacity: 0.9;
	}

	#admin-stacks {
		margin: 48px 0;
		display: flex;
		flex-wrap: wrap;
		& > a {
			width: 33.33%;
		}
		.stack span.font-s {
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			height: 3rem;
		}
	}

	.stack {
		display: flex;
		gap: 15px;
		width: 100%;
		background: transparent;
		padding: 15px;
		border-radius: 15px;
		transition: all 200ms ease;
		border: 1px solid transparent;
	}

	.stack:hover {
		border-color: #d9dee0;
		background: #e7ebee;
		box-shadow: 0 0 10px -7px #7d7f82;
	}

	.stack > div:first-child {
		color: white;
		font-size: 24px;
		border-radius: 10px;
		height: 100%;
		aspect-ratio: 1;
	}

	.stack > div:last-child {
		text-align: left;
		display: flex;
		flex-direction: column;
		padding: 10px 0 4px;
	}
	@media screen and (max-width: 1000px) {
		#admin-stacks a {
			width: 50%;
		}
	}
	@media screen and (max-width: 630px) {
		#admin-stacks a {
			width: 100%;
		}
	}
}
