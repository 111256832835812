@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

html,
body {
	box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
img,
p,
button,
input,
textarea,
a {
	margin: 0;
	padding: 0;
	font-family: "Poppins", sans-serif !important;
	color: var(--black);
}

input,
button,
textarea {
	outline: none;
	border: none;
}

input,
textarea {
	font-size: 1rem;
	background: transparent;
	width: 100%;
}

textarea {
	resize: none;
}

button {
	cursor: pointer;
	background: none;
}

a {
	opacity: 1 !important;
	display: inherit;
	text-decoration: none;
}

:disabled {
	cursor: not-allowed;
}

:root {
	--black: #333;
	--gray: #7c7c7c;
	--border: #3333333e;
	--neon-light: #ebf6cc;
	--neon: #9cd400;
	--danger: #ff5722;
	--neon-dark: #61732b;
}

hr {
	border: none;
	height: 1px;
	background: var(--border);
	opacity: 0.5;
	padding: 0 1rem;
}

#App,
.overlay {
	max-width: 1440px;
	margin: auto;
	position: relative;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.disabled {
	opacity: 0.5;
}

.link {
	display: inline;
	text-decoration: underline;
}

.w-full {
	width: 100% !important;
}

.w-fit {
	width: fit-content;
}

.h-full {
	height: 100%;
}

.text-center {
	text-align: center;
}

.text-ellipse {
	--lines: 2;
	display: -webkit-box;
	-webkit-line-clamp: var(--lines);
	text-overflow: ellipsis;
	overflow: hidden;
	max-height: calc((1.5 * var(--lines)) * 1rem);
	-webkit-box-orient: vertical;
	&.nowrap {
		height: auto;
		display: block;
		white-space: nowrap;
		width: 39rem;
	}
}

.table-wrapper {
	box-shadow: 0 0 10px -5px gray;
	border-radius: 15px;
	margin: 30px 0;
	&.no-shadow {
		box-shadow: none;
	}
}

.scrollable {
	overflow: auto;
	max-height: 92vh;
	margin: 4vh auto 3vh;
	/* &::-webkit-scrollbar {
		width: 20px;
		height: 20px;
	} */
}

.no-gap {
	gap: 0 !important;
}

.gap-xl {
	gap: 20px;
}

.gap-m {
	gap: 10px;
}

.gap-xs {
	gap: 5px;
}

.flex {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex.start {
	align-items: flex-start;
}

.flex.row-start {
	justify-content: flex-start;
}

.flex.row-end {
	justify-content: flex-end;
}

.flex.row {
	justify-content: space-between;
}

.flex.col {
	flex-direction: column;
}

.container-w {
	margin: auto;
	width: 85%;
}

#App > #container {
	padding: 0 1.5rem;
}

#App > #container {
	margin: auto;
}

.section {
	border-radius: 15px;
	border: 0.5px solid var(--border);
	margin: 36px 0;
	padding: 20px 26px;
}

.theme-font {
	color: #9cd400;
}

.danger-font {
	color: red;
}

.master-heading {
	font-size: 32px;
	font-weight: 600;
	line-height: normal;
	text-transform: capitalize;
	white-space: nowrap;
}

.heading {
	font-size: 26px;
	font-weight: 600;
	line-height: normal;
	text-transform: capitalize;
}

.subheading {
	font-size: 24px;
	font-weight: 600;
	line-height: normal;
	text-transform: capitalize;
}

.font-l {
	font-size: 22px;
}

.font-m {
	font-size: 18px;
}

.font-md {
	font-size: 16px;
}

.font-s {
	opacity: 0.7;
	font-size: 15px;
}

.font-xs {
	opacity: 0.7;
	font-size: 13px;
}

.nowrap > * {
	white-space: nowrap;
}

.theme-btn,
.theme-input,
.select {
	padding: 15px 20px;
	border-radius: 5px;
	white-space: nowrap;
	background: #fff;
	border: 1px solid var(--border);
}

input,
.theme-input,
.theme-btn {
	opacity: 1 !important;
}

.theme-input.full {
	width: -webkit-fill-available !important;
}

.theme-btn.theme-icon {
	border-radius: 5rem;
	aspect-ratio: 1;
	font-size: 18px;
	padding: 17px;
}

.theme-btn.icon {
	border-radius: 5rem;
	padding: 5px;
	background: var(--black);
}

.theme-btn.rounded {
	border-radius: 99rem;
	aspect-ratio: 1;
}

.theme-btn.icon svg {
	color: #fff;
}

.theme-btn.w-m {
	min-width: 110px;
}

.theme-btn.small {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 8px 12px;
	background: var(--neon-light);
}

.theme-btn {
	gap: 10px;
	text-transform: capitalize;
}

.theme-btn.primary {
	overflow: hidden;
	background: var(--neon);
	color: #fff;
}

.theme-btn.secondary {
	background: var(--neon-light);
}

.theme-btn.grey {
	background: #e3e3e3 !important;
	color: var(--gray);
}

.theme-btn.outlined {
	border: 1px solid var(--border);
	&.small {
		background: none;
	}
}

.theme-btn.outlined.theme-font {
	border-color: var(--neon);
	font-weight: 600;
}

.theme-btn.danger {
	background: var(--danger);
	color: white;
	svg {
		fill: white;
	}
}

.theme-btn.danger.theme-btn.outlined {
	border-color: var(--danger);
	background: transparent;
	color: var(--danger);
	font-weight: 600;
	svg {
		fill: var(--danger);
	}
	* {
		color: var(--danger);
	}
}

button.form-submit {
	font-size: 1rem;
	margin: 50px auto 80px;
	width: 40%;
	border-radius: 2rem;
}

.overlay {
	position: fixed;
	top: 50%;
	left: 50%;
	translate: -50% -50%;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.6);
	overflow: hidden;
	z-index: 100;
}

.prompt {
	overflow: hidden;
	min-width: 50vw;
	max-width: 580px;
	background: white;
	padding: 20px 26px;
	border-radius: 12px;
}

.prompt h2 {
	margin-bottom: 6px;
}

.prompt .obj-info {
	display: flex;
	flex-direction: column;
	margin: 10px 0;
}

.prompt .modal-actions {
	margin-top: 30px;
	gap: 10px;
	justify-content: flex-end;
}

.select {
	gap: 10px;
	position: relative;
}

.select > div:first-child {
	width: 100%;
	gap: 10px;
}

.select > div:first-child > span {
	width: 100%;
	display: block;
	user-select: none;
}

.select .no-value {
	opacity: 0.7 !important;
}

.select-dropdown {
	box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.15);
	width: calc(100% - 40px);
	padding: 10px 20px;
	border-radius: 15px;
	position: absolute;
	background: white;
	display: flex;
	flex-direction: column;
	gap: 8px;
	z-index: 100;
	max-height: calc(250px - 20px);
	overflow: auto;

	left: 0;
	top: 105%;

	&.left {
		left: -100%;
		top: 0;
		right: 100%;
		translate: -10px -2px;
	}
	&.mid {
		top: 50%;
		translate: -10px -50%;
	}

	&::-webkit-scrollbar {
		width: 2px;
	}
}

.select-dropdown button {
	background: none;
	font-size: 1rem;
	padding: 3px 0;
	text-align: left;
}

nav {
	display: flex;
	justify-content: space-between;
	padding: 15px 20px;
}

nav img#logo {
	width: 180px;
}

.search-bar {
	gap: 16px;
}

.page-title-wrapper * {
	text-transform: capitalize;
	.master-heading + small {
		display: block;
		margin-bottom: 15px;
	}
}

table {
	width: 100%;
	border-collapse: collapse;
}

thead {
	position: sticky;
	top: 0;
	background: white;
	z-index: 10;
}

tr:nth-child(even) {
	background: #f0f0f0;
}

tr.font-md > th:first-child {
	padding-left: 20px;
}

tr.font-md > th:last-child {
	padding-right: 20px;
}

td,
th {
	padding: 1.25rem 1.75rem;
	text-align: right;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.center {
	text-align: center;
}

td button.edit {
	margin-left: 40px;
}

.spinner-wrapper {
	width: 100%;
	height: 100%;
	z-index: 1000;
	background: rgb(255, 255, 255);
	&.absolute {
		top: 0;
		left: 0;
	}
	&.transparent {
		background: transparent;
	}
}

.underline {
	text-decoration: underline;
}

.pointer {
	cursor: pointer;
	user-select: none;
}

.theme-btn .loader.progress-bar {
	width: 90%;
	background: var(--neon);
	height: 100%;
}

.slider-wrapper {
	z-index: 10000;
	position: relative;
	#slides-container {
		width: 100vw;
		height: 100vh;
		overflow: hidden;

		& > div {
			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			transition: all 200ms ease;
		}

		img {
			user-select: none;
			object-fit: contain;
			min-width: 100%;
			min-height: 100%;
			max-width: 100%;
			max-height: 100%;
		}
	}
	.dir-btns-wrapper {
		position: absolute;
		bottom: 10px;
		display: flex;
		gap: 10px;
		left: 50%;
		translate: -50%;
		margin-top: 20px;
		z-index: 1;

		button {
			background: rgba(0, 0, 0, 0.3);
			border: 1px solid rgb(123, 123, 123);
			border-radius: 50%;
			aspect-ratio: 1;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 25px;
				stroke: white;
				fill: white;
			}
		}
	}
}

.order-filters-modal {
	background: white;
	border-radius: 15px;
	.heading-wrapper {
		margin-bottom: 20px;
	}
	& > div > div {
		margin: 20px 30px 40px;
	}
	.content div {
		width: -webkit-fill-available;
	}
	input {
		width: -webkit-fill-available;
	}
	button.search {
		width: 100px;
		justify-content: center;
	}
	button.close {
		display: flex;
		padding: 5px;
		margin-left: 16px;
		border-radius: 100%;
		transition: border-color 200ms ease;
		font-size: 20px;
		border: 1px solid transparent;
	}
	button.close:hover {
		border-color: var(--border);
	}
}

.loader.progress-bar {
	width: 100%;
	height: 4.8px;
	display: inline-block;
	position: relative;
	background: transparent;
	overflow: hidden;
	&::after {
		content: "";
		width: 192px;
		height: 4.8px;
		background: #fff;
		position: absolute;
		top: 50%;
		left: 0;
		translate: 0 -50%;
		box-sizing: border-box;
		animation: progress-bar 2s linear infinite;
	}
}

.loader.pulse {
	width: 56px;
	height: 56px;
	display: inline-block;
	position: relative;
	&::after,
	&::before {
		content: "";
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background: var(--gray);
		position: absolute;
		opacity: 0;
		left: 0;
		top: 0;
		animation: animloader 2s linear infinite;
	}
	&::after {
		animation-delay: 1s;
	}
}

.loader.spinner {
	width: 36px;
	height: 36px;
	border: 5px solid var(--border);
	border-bottom-color: #ff3d00;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.loader.default {
	width: 1.5rem;
	height: 1.5rem;
	border: 2px solid var(--black);
	border-bottom-color: transparent;
	border-left-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes animloader {
	0%,
	5% {
		transform: scale(0);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}

@keyframes progress-bar {
	0% {
		left: 0;
		transform: translateX(-100%);
	}
	100% {
		left: 100%;
		transform: translateX(0%);
	}
}

@keyframes floating {
	0% {
		transform: translate(0, 0px);
	}
	50% {
		transform: translate(15px, 0);
	}
	100% {
		transform: translate(-0px, 0);
	}
}

@media screen and (max-width: 768px) {
	.container-w {
		width: 90%;
	}
}
