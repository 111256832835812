.blog-form {
	#blog-cover-wrapper {
		margin: 2rem 0 1rem;
		border-radius: 0.5rem;
		overflow: hidden;
		aspect-ratio: 3/1;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	form {
		.theme-input {
			border: none;
		}
		textarea {
			padding: 0;
			height: fit-content;
			text-transform: none;
		}
		textarea[name="title"] {
			margin: 2rem 0 1.25rem;
		}
	}
	.quill {
		position: relative;
	}
	.ql-editor {
		line-height: 1.6;
		a {
			display: inline-block;
		}
	}
	.ql-toolbar.ql-snow {
		position: sticky;
		top: 0;
		background: white;
		z-index: 100;
		border-width: 0;
		border-bottom-width: 1px;
	}
	.ql-container {
		font-size: 1.05rem;
		margin-bottom: 2rem;
		&.ql-snow {
			border: none;
		}
	}
	.cover-placeholder {
		background: #21212116;
		svg {
			opacity: 0.5;
		}
	}
	.cover-actions {
		top: 1rem;
		right: 1rem;
		display: flex;
		button {
			display: flex;
		}
	}
}
