.highlight-btn {
	padding: 5px 12px;
	border-radius: 2rem;
	font-size: 14px;
	color: #fff;
	width: 100%;
	&.default {
		background: #607d8b;
	}
	&.settlement {
		padding: 0;
		display: flex;
		overflow: hidden;
		align-items: stretch;
		span {
			padding: 5px 10px 5px 5px;
			display: block;
		}
	}
	&.settlement > div,
	&.btn-loader {
		background: #00000012;
		border-radius: 1rem;
		aspect-ratio: 1 / 1;
		width: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
		svg {
			font-size: 1rem;
			color: white;
		}
		.spinner-wrapper {
			width: fit-content;
		}
		.loader.default {
			width: 1rem;
			height: 1rem;
		}
	}
	&.placed,
	&.yellow {
		background: #ffc107;
	}
	&.blue,
	&.confirmed {
		background: #2196f3;
	}
	&.green,
	&.delivered,
	&.settlement.true {
		background: #00bf5f;
	}
	&.red,
	&.cancelled {
		background: var(--danger);
	}
}
