#businesses {
	& > div:nth-child(2) {
		margin: 20px 0 30px;
	}

	& > div:nth-child(3) {
		margin: 20px 0 30px;
		gap: 30px;
	}

	#city-filter {
		width: 400px;
	}

	#nsort {
		width: 300px;
	}

	.master-heading + div {
		display: flex;
		gap: 8px;
		width: 100%;
	}

	.search-bar {
		width: 80%;
	}

	.ncard {
		border-radius: 15px;
		background: #fff;
		display: flex;
		margin-bottom: 25px;
		overflow: hidden;
		border: 0.1px solid #7c7c7c11;
		box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.12);
	}

	.ncard-content {
		padding: 10px 26px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.ncard-img {
		width: 50%;
		aspect-ratio: 2;
	}

	.ncard-img img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		display: block;
	}

	.ncard p {
		margin: 5px 0 15px;
	}

	.ndetails {
		display: flex;
		align-items: center;
		gap: 10px;
		opacity: 0.9;
		margin-bottom: 5px;
	}

	.ncard a {
		text-decoration: underline;
	}

	.cards-wrapper {
		margin: 40px 0;
	}

	.nnums {
		gap: 10px;
	}
	.top-content {
		display: flex;
		justify-content: space-between;
	}
	.business-image-placeholder {
		background: #00bf0021;
		color: #90d290;
		& svg {
			font-size: 48px;
		}
	}
	.featured {
		display: block;
		text-align: center;
		flex: 1;
		padding: 5px 24px 5px 10px;
	}
	.overlay:has(#business-images) {
		z-index: 10;
	}
	#business-images {
		width: 70%;
		height: 80vh;
		background: white;
		border-radius: 1rem;
		padding: 2rem;
		overflow: auto;

		button.close {
			margin-left: auto;
			width: fit-content;
			display: block;
			font-size: 1.65rem;
			position: absolute;
			top: 2rem;
			right: 2rem;
		}
		.subheading {
			margin-bottom: 1rem;
		}
		#business-cover {
			margin-bottom: 1.5rem;
			width: 100%;
		}
		#modal-business-images {
			display: flex;
			flex-wrap: wrap;
		}
		img {
			object-fit: cover;
			width: calc(20% - 2px);
			aspect-ratio: 1;
			border-radius: 2px;
			margin-right: 2px;
			margin-bottom: 2px;
		}
	}

	/* .featured {
		margin: 1.25rem 1.25rem 0 0;
		position: relative;
		height: fit-content;
		& > svg {
			font-size: 2rem;
			color: var(--neon);
		}
		& > span.count {
			font-size: 0.72rem;
			position: absolute;
			top: 50%;
			left: 50%;
			translate: -47% -86%;
			color: white;
		}
		& > div.placeholder {
			width: 20px;
			padding: 6px;
			& span {
				width: 100%;
				border: 2px solid var(--neon);
				border-radius: 50%;
				aspect-ratio: 1;
				display: block;
			}
		}
	} */
}
