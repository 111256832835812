#product-form .container {
	margin: 50px auto;
}

#product-form .container > div:first-child {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.p-fields {
	display: flex;
	gap: 15px;
	margin: 30px 0;
}

.p-fields.wrap {
	flex-wrap: wrap;
}

.p-fields > div {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	width: calc(50% - 7.5px);
	height: fit-content;
	position: relative;
}

.p-fields.small > div {
	width: calc(33.33% - 10px);
}

.p-fields button.remove {
	position: absolute;
	right: 16px;
	top: 50%;
	translate: 0 -50%;
	display: flex;
	opacity: 0.7;
}

.p-fields.full > div {
	width: 100%;
}

.p-fields > div > input,
.p-fields > div > textarea {
	width: auto;
}

.p-fields label {
	margin-bottom: 5px;
}

.p-fields input.removable {
	padding-right: 50px;
}
