#gallery {
	--col: 3;
	--image-width: calc(100 / var(--col) * 1%);
	padding-bottom: 50px;
	.album:hover,
	.album-view,
	.curr-album {
		box-shadow: 0px 6px 18px 2px #0000002f;
	}
	.curr-album {
		overflow: hidden;
		margin: 36px 0;
		border-radius: 8px;
		padding: 24px;
		& > div:first-child {
			margin-bottom: 26px;
		}
		.album-actions {
			margin-top: 26px;
		}
		.images-section {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			gap: 10px;
		}
	}
	.image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		aspect-ratio: 5/4;
		width: calc(33.33% - 6.66px);
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		&.placeholder {
			background-color: rgb(224, 224, 224);
			svg {
				font-size: 1.5rem;
				opacity: 0.4;
			}
		}
		.selection {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			&:has(.checked) {
				background: rgba(0, 0, 0, 0.2);
			}
			.mark-wrapper {
				cursor: pointer;
				position: absolute;
				right: 0.8rem;
				bottom: 0.8rem;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 24px;
				width: 24px;
				border: 1px solid white;
				background-color: rgba(0, 0, 0, 0.4);
				&.checked {
					border-color: var(--neon);
					background-color: var(--neon);
				}
				.mark {
					fill: white;
				}
			}
		}
		.undo-btn {
			background: #b1b1b15d;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			svg {
				font-size: 20px;
				padding: 8px;
				background: #ff000071;
				color: white;
				border-radius: 2rem;
				border: 1px solid #ff00001f;
			}
		}
	}
	.album {
		overflow: hidden;
		transition: all 300ms ease;
		margin: 30px 0;
		border: 1px solid var(--border);
		border-radius: 10px;
		.cover-image {
			align-self: stretch;
			width: 33.33%;
			.album-icon {
				position: absolute;
				font-size: 32px;
				top: 6%;
				left: 6%;
				padding: 3px 5px;
				border-radius: 5px;
				color: white;
				background: #00000082;
			}
		}
		.album-content {
			padding: 25px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-self: stretch;
			width: 66.66%;
			.actions-wrapper {
				margin-top: 10px;
			}
		}
	}
	.album-view {
		overflow: hidden;
		margin: 36px 0;
		border-radius: 8px;
		padding: 26px;
		h6 {
			margin: 30px 0 10px;
		}
		.grid {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
		}
		.back-btn {
			gap: 5px;
			margin-bottom: 10px;
			padding: 5px 12px 5px 6px;
			border-radius: 2rem;
			transition: all 300ms ease;
			&:hover {
				background: var(--border);
			}
		}
	}
}
